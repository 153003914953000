@import '../../assets/scss/variables.scss';

.project-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background-color: $whiteColor;
  overflow: 'hidden',
}

.project-modal-left-content {
  height: 100%;
  padding: 15px;
  overflow: hidden;
  height: 100%;
}

.project-modal-stepper-container {
  display: flex;
  align-items: flex-end;
}

.project-modal-right-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.project-modal-grid-parent-container {
  height: 100%;
}

.project-modal-label-container {
  margin-top: 25px;
  margin-bottom: 10px;
}

.project-modal-title-label {
  font-size: 30px !important;
  line-height: 40px !important;
  font-weight: 500 !important;
  color: $whiteColor;
}

.project-close-button {
  width: 30px;
  height: 30px;
  position: absolute !important;
  top: 20px;
  right: 20px;
}

.project-modal-content {
  display: flex;
  flex: 1;
  padding: 40px 40px 0px;
}

//Layout styles
.project-layout-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-layout-container {
  display: flex;
  flex: 1; 
}

.project-layout-title {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26.54px !important;
}

.project-layout-sub {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 26.54px !important;
  color: $grey82;
}

.project-modal-footer-container {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
}


// Steps

.project-modal-step-button {
  background-color: $lightGreenColor !important;
  border-radius: 8px !important;
  color: $whiteColor;
  min-width: none;
}

.project-modal-step-exit-button {
  background-color: $modalExitGray !important;
  border-radius: 8px !important;
  color: $whiteColor;
  margin-right: 10px !important;
  min-width: 68px !important;
}

.project-modal-step-landing {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.project-modal-step-content {
  display: flex;
  flex: 1;
  padding-top: 40px;
  justify-content: center;
  flex-direction: column;
}

.project-modal-step-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.project-modal-step-form-row {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}



