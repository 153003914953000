@import '../../assets/scss/variables.scss';

.logo {
  width: 10px;
  margin: 22px auto 30px;
  background: url(../../assets/img/logo-white-icon.svg) no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 18px;
}

.logoContainer {
  pointer-events: none !important;
  background-color: $black10 !important;
}

.projects-menu {
  margin: 12px;
}

.project-item {
  max-height: 24px;
  margin-bottom: 10px;
}

.project-item-badge {
  margin-right: 18px;
  margin-top: 2px;
}

.settings-menu .MuiMenu-paper {
  background-color: $black10;
  & .MuiMenuItem-root{
    color: $grey82;
    &:hover{
      background-color: $darkGrey3;
      color: $whiteColor;
      border-radius: '5px';
      border-color: $grey82;
    }
    }
  }

  .MuiDrawer-paper.MuiPaper-root {
    overflow-y: auto;
  /* Hide scrollbar for Internet Explorer, Edge and Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  }