@import '../../assets/scss/variables.scss';

.amplify-input {
  border-radius: 10px;
}

:root, [data-amplify-theme] {
  --amplify-components-authenticator-container-width-max: 360px;
  --amplify-components-authenticator-router-border-style: none;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-button-primary-background-color: #354646;
  --amplify-components-button-primary-hover-background-color: #9aabab;
  --amplify-components-fieldcontrol-border-radius: 10px;
  --amplify-components-fieldcontrol-background-color: #fff;
  --amplify-components-authenticator-router-background-color: unset;
  --amplify-components-passwordfield-button-background-color:  #354646;
  --amplify-components-button-link-background-color:  #354646;
  --amplify-components-button-link-color: #9aabab;
  --amplify-components-button-disabled-background-color: #354646;
  --amplify-components-divider-label-background-color: #354646;
  --amplify-components-divider-label-color: #fff;
}

.amplify-input {
  background-color: #fff;
}

.amplify-label {
  display: none;
}

.amplify-divider {
  border-color: #000;
}

.amplify-field__show-password {
  background-color: #fff;
}

.federated-sign-in-button {
  background-color: #fff;
  border-radius: 10px;
}

.amplify-alert__dismiss {
  background-color: var(--amplify-components-alert-error-background-color)
}

.login-logo {
  margin-bottom: 16px;
  padding: 0 30px 0 30px;
}

.login-container {
  background: url(../../assets/img/login-bg.jpeg) no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;

  .login {
    display: flex;
    flex-direction: column;
    // border: 1px solid white;
    border-radius: 32px 32px 0 0;
    padding: 45px;
    float: left;
    background-image: linear-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0.3) 80%, rgba(255,255,255,0) 100%);
    margin-left: 10vw;
    margin-top: 10vh;

    @media screen and (max-width: 450px) {
      margin: 0 auto;
      padding: 25px;
      width: 300px;
      float: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .header1 {
      font-size: 24px;
    }

    .header2 {
      font-size: 16px;
    }

    .divider-text {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $greyColor;
      line-height: 0.1em;
      margin: 15px 0 15px 0;
      color: $greyColor;

      span {
        background: $whiteColor;
        padding: 0 10px;
      }
    }

    form {
      padding: 20px 0;
      text-align: left;

      .error {
        color: $redColor;
        min-height: 25px;
        width: 100%;
        font-size: 12px;
      }

      .success {
        font-size: 12px;
      }

      .button-wrapper {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        flex-direction: column;

        .ant-btn-primary {
          background-color: $primaryColor;
          border-color: $primaryColor;
        }

        .ant-btn-primary:hover {
          background-color: $greyColor;
          border-color: $greyColor;
        }

        .link-text {
          font-size: 12px;
        }

        .link-text #text {
          font-size: 12px;
          color: $whiteColor;
        }

        .ant-btn-link {
          font-size: 12px;
          text-align: left;
          padding: 0;
          color: $whiteColor;
        }

        .ant-btn-link:hover {
          color: $sandColor;
        }
      }
    }
  }
}
