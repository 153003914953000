@font-face {
  font-family: 'Mada-Black';
  src: local('Mada-Black'), url(../font/Mada-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Mada-Bold';
  src: local('Mada-Bold'), url(../font/Mada-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Mada-ExtraLight';
  src: local('Mada-ExtraLight'),
    url(../font/Mada-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Mada-Light';
  src: local('Mada-Light'), url(../font/Mada-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Mada-Medium';
  src: local('Mada-Medium'), url(../font/Mada-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Mada-Regular';
  src: local('Mada-Regular'), url(../font/Mada-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Mada-SemiBold';
  src: local('Mada-SemiBold'), url(../font/Mada-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Degular-Light';
  src: local('Degular-Light'), url(../font/Degular-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Degular-Medium';
  src: local('Degular-Medium'), url(../font/Degular-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Degular-Regular';
  src: local('Degular-Regular'), url(../font/Degular-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Degular-Semibold';
  src: local('Degular-Semibold'), url(../font/Degular-Semibold.otf) format('opentype');
}
