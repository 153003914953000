@import './fonts.scss';
$regularFont: Degular-Regular, Tahoma, sans-serif !important;
$lightFont: Degular-Light, Tahoma, sans-serif !important;
$boldFont: Degular-Semibold, Tahoma, sans-serif !important;
$primaryColor: #354646;
$secondaryColor: #8b9a9a;
$whiteColor: #fff;
$blackColor: #031718;
$black10: #101010;
$tealColor: #255751;
$lightGreyColor: #f2f2f2;
$greyColor: #9aabab;
$greyColor55: #c7d1d1;
$greyColor40: #d6dddd;
$greyColor25: #e6eaea;
$greyColor10: hsl(180, 8%, 97%);
$greyColorBackground: #fafafa;
$oceanColor: #609699;
$stoneColor: #baba7d;
$lightMint: #d7e5e5;
$grassColor: #7da34a;
$redColor: #510d0a;
$perennialRed: #eb5757;
$orangeColor: #ffa500;
$purpleColor: #d4c5e2;
$mediumAquamarine: #6fcf97;
$lightBlue: #dcf4ff;
$lightBlue2: #88cada;
$redTag: #ea5d31;
$philippineSilver: #b1b1b1;
$darkGrey: #ffffff33;
$darkGrey2: #ffffff4d;
$darkGrey3: #d9d9d933;
$lightGrey2: #f5f5f5;
$softBlue: #719fea;
$darkGrey37: #373737;
$darkGrey4f: #4f4f4f;
$grey82: #828282;
$lightGrey3: #eaedf7;
$lightSilver: #d9d9d9;
$culturedWhite: #f4f4f4;
$brightGray: #e9e9e9;
$black15: #313131;
$black20: #232323;
$black25: #2c2c2c;
$searchBlue: #f7f9fb;
$lightGrey: #353535;
$sandColor: #ecedb2;
$mintColor: #acdbdf;

//tiers
$lemonYellow: #ebf3a6; //tier 1
$lightGreenColor: #ceda78; // tier 2
$tealBlue: #57b4da; //tier 4

//masks
$structureOrange: #e96d27;
$treeGreen: #3cd09b;
$elevationPink: #e927b2;
$waterBlue: #88cada; //tier 3
$otherYellow: #f5b91f;
$grasslandGreen: #93CE15;

//project colors
$project0: #a1b129;
$project1: #88cada;
$project2: #ca0286;
$project3: #ea5d31;
$project4: #eaa031;
$project5: #b931ea;

$mainBlackColor: #1c1c1c;
$secondaryBlack: #292929;
$black: #000000;
$stratificationBlack: #1f1f1f;
$modalBlackHeader: #060606;
$modalExitGray: #d8d8d8;
$limitSOCTons: #dd2c00;

$terrainFillColor: rgba(66, 100, 251, 0.3);
$terrainOutlineColor: rgba(66, 100, 251, 1);
$linksColor: rgba(44, 174, 205, 1);

$blackWithOpacity: rgba(0, 0, 0, 0.8);
$completeFildContainerColor: rgba(255, 255, 255, 0.1);
$completeFildTextColor: rgba(231, 231, 231, 1);
$completeFildIconColor: rgba(185, 185, 185, 1);
$unselectedTextColor: rgba(255, 255, 255, 0.7);
$cancelModalButton: #bebebe;
$copyLocationButton: #909090;

// allow access to theme colors in js
:export {
  whiteColor: $whiteColor;
  blackColor: $blackColor;
  black10: $black10;
  tealColor: $tealColor;
  greyColor: $greyColor;
  greyColor55: $greyColor55;
  greyColor40: $greyColor40;
  greyColor25: $greyColor25;
  oceanColor: $oceanColor;
  mintColor: $mintColor;
  sandColor: $sandColor;
  stoneColor: $stoneColor;
  grassColor: $grassColor;
  lightGreenColor: $lightGreenColor;
  redColor: $redColor;
  perennialRed: $perennialRed;
  orangeColor: $orangeColor;
  purpleColor: $purpleColor;
  mediumAquamarine: $mediumAquamarine;
  lightMint: $lightMint;
  redTag: $redTag;
  philippineSilver: $philippineSilver;
  lightGrey2: $lightGrey2;
  lightBlue: $lightBlue;
  softBlue: $softBlue;
  structureOrange: $structureOrange;
  treeGreen: $treeGreen;
  elevationPink: $elevationPink;
  waterBlue: $waterBlue;
  otherYellow: $otherYellow;
  lightGrey3: $lightGrey3;
  grey82: $grey82;
  darkGrey37: $darkGrey37;
  project0: $project0;
  project1: $project1;
  project2: $project2;
  project3: $project3;
  project4: $project4;
  lightSilver: $lightSilver;
  mainBlackColor: $mainBlackColor;
  secondaryBlack: $secondaryBlack;
  terrainFillColor: $terrainFillColor;
  terrainOutlineColor: $terrainOutlineColor;
  culturedWhite: $culturedWhite;
  brightGray: $brightGray;
  black15: $black15;
  black20: $black20;
  black25: $black25;
  searchBlue: $searchBlue;
  black: $black;
  stratificationBlack: $stratificationBlack;
  greyColor10: $greyColor10;
  lightGrey: $lightGrey;
  greyColorBackground: $greyColorBackground;
  completeFildContainerColor: $completeFildContainerColor;
  completeFildTextColor: $completeFildTextColor;
  completeFildIconColor: $completeFildIconColor;
  otherYellow: $otherYellow;
  unselectedTextColor: $unselectedTextColor;
  linksColor: $linksColor;
  modalBlackHeader: $modalBlackHeader;
  cancelModalButton: $cancelModalButton;
  modalExitGray: $modalExitGray;
  lemonYellow: $lemonYellow;
  tealBlue: $tealBlue;
  grasslandGreen: $grasslandGreen;
  blackWithOpacity: $blackWithOpacity;
  copyLocationButton: $copyLocationButton;
  limitSOCTons: $limitSOCTons;
}
