@import '../../assets/scss/variables.scss';

.customstepper-step {
  margin-bottom: 10px;
  border-radius: 10px;
  color: $whiteColor;
}

.customstepper-step-label {
  color: $whiteColor;
  font-size: 14px !important;
}

.customstepper-step-counter-label {
  color: $whiteColor;
  font-size: 14px !important;
  margin-left: 8px !important;
}
