@import 'antd/dist/antd.css';
@import './assets/scss/variables.scss';

body {
  font-family: $regularFont;
  height: 100vh;
  width: 100%;
  min-width: 1200px;
  overflow-x: auto;
}

.page-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 33px;
  padding: 16px 32px;
  background-color: $whiteColor;
  font-family: $boldFont;
  color: $blackColor;
}

.page-title-dark {
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  padding: 16px 32px;
  background-color: $black10;
  font-family: $lightFont;
  color: $whiteColor;
  height: 63px;
}

.edit-form {
  max-height: 500px;
  overflow: auto;
  margin-bottom: 20px;
  padding: 20px;
}

.tool-bar {
  padding: 20px;
  display: flex;
  background: $greyColor25;
  border-radius: 8px 8px 0px 0px;
  margin: 20px 50px 0;
  justify-content: space-between;

  .tool-icon {
    font-size: 24px;
    box-shadow: none;
    color: $greyColor;
  }
}

.ant-table-body {
  overflow-y: auto !important;
  height: calc(100vh - 330px);
}

.table-content {
  height: calc(100vh - 205px);
  margin: 0 50px;
  border-radius: 0px 0px 8px 8px;
  flex: 1;
  background-color: $whiteColor;

  .ant-pagination {
    padding: 0 20px;
    background-color: $whiteColor;
  }
}

.table-content-model {
  flex: 1;
  background-color: $whiteColor;

  .ant-table {
    height: 450px;
    overflow: auto;
    margin-top: 10px;
  }
}

.ant-table-thead {
  .ant-table-cell {
    font-weight: bold;
  }
}

.ant-table-cell {
  .ant-space {
    .anticon {
      font-size: 20px;
    }
  }
}

.column-chooser {
  .ant-menu-item {
    height: 20px !important;
    line-height: 20px !important;
  }
}

.btn-primary-dark {
  border-radius: 15px;
  min-width: 100px;
}

.btn-primary-default {
  border-radius: 15px;
  min-width: 100px;
}

.btn-browse {
  background-color: transparent;
  color: $blackColor;
  border: 1px $blackColor solid;
  padding: 8px 16px 8px 16px;
  height: 36px;
  border-radius: 50px;
  margin: 4px;
}

.btn-browse:hover {
  background-color: $lightBlue2;
}

.btn-primary-transparent {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.ant-modal-header {
  background-color: $blackColor;
  border-radius: 15px 15px 0px 0px;

  .ant-modal-title {
    font-family: $boldFont;
    color: $whiteColor;
    font-size: 20px;
  }

  .ant-modal-close-icon {
    color: $whiteColor;
  }
}

.ant-modal-content {
  border-radius: 15px;
}

.ant-modal-footer,
.ant-modal-confirm-btns {
  .ant-btn {
    border-radius: 15px;
    min-width: 100px;
  }
}

.selected-menu-item {
  background: $greyColor25;
}

.dynamic-delete-button {
  font-size: 24px;
}

.ant-space-item > .ant-form-item {
  margin-bottom: 2px;
  font-size: 16px;
}

.ant-modal-close-icon {
  color: $whiteColor;
}

.ant-table-selection-column {
  .ant-radio-wrapper {
    display: none;
  }
}

.full-field-info {
  background-color: $whiteColor;
  width: 100%;

  .field-container {
    padding: 30px;
    height: 100vh;
    overflow-y: auto;
    // /* Hide scrollbar for IE, Edge and Firefox */
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */

    // /* Hide scrollbar for Chrome, Safari and Opera */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  .text-information {
    margin-top: 15px;
    overflow: auto;
    height: calc(100vh - 125px);
  }
}

.ant-layout-content {
  background-color: $blackColor;
}

.ant-statistic-title {
  margin-bottom: 2px;
  font-size: 20px;
  color: rgba($blackColor, 0.35);
}

.ant-statistic-content {
  font-size: 0px;
  padding-bottom: 10px;
}

.ant-statistic-content-value {
  font-size: 16px;
  padding-top: 0px;
}

.padding30 {
  padding: 0 30px !important;
}

.detail-panel {
  padding-top: 80px;
}

.detail-view-container {
  height: 100vh;
  overflow: auto;
  background: $whiteColor;
  border-left: 1px solid lightgray;

  .field-label {
    width: 100%;
    color: rgba($blackColor, 0.45);
  }

  .info-text {
    color: $blackColor;
    padding-bottom: 10px;
  }

  .ant-row {
    flex-flow: row wrap;
    padding-top: 10px;
  }
}

.capitalize,
.capitalize > label {
  text-transform: capitalize;
}

.map-container {
  width: 77%;

  .map-control-panel {
    padding: 15px;
    font-weight: bold;

    .ant-radio-group-outline {
      border-radius: 5px;
    }
  }

  .map-control-panel-timeline {
    background-color: rgba($whiteColor, 0.8);
    width: 100%;
    height: 80px;
    margin: 0 auto;
    bottom: 0;
    position: absolute;

    .button-back {
      left: 10px !important;
    }

    .button-forward {
      right: 10px !important;
    }
  }
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.search-box {
  height: 36px;
  background: $greyColor25;
  border: 1px solid $greyColor55;
  box-sizing: border-box;
  border-radius: 7px;

  .ant-input {
    background: $greyColor25;
  }
}

.ant-btn {
  font-family: $boldFont;
}

.ant-table-thead th.ant-table-column-sort {
  background: $greyColor25;
}

.ant-switch-checked {
  background-color: $sandColor !important;
}

.td.ant-table-column-sort {
  background: transparent;
}

.ant-tag-success {
  color: $grassColor;
  background: $greyColor40;
  border-color: $grassColor;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-pagination-item-active,
.ant-btn-dangerous,
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus,
.ant-btn-dangerous:active,
.btn-primary-dark,
.btn-primary-dark:active,
.btn-primary-dark:focus {
  background-color: $oceanColor;
  border: $primaryColor;
  color: $whiteColor !important;
}

.btn-primary-dark:hover {
  background-color: $greyColor40;
}

.ant-pagination-item-active a {
  color: $whiteColor !important;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
}

div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

td.ant-table-column-sort {
  background: transparent;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  background-color: $greyColor;
}

div.sider::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba($blackColor, 0.3);
  box-shadow: inset 0 0 6px rgba($blackColor, 0.3);
}

div.sider::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba($blackColor, 0.3);
  box-shadow: inset 0 0 6px rgba($blackColor, 0.3);
}

.invite-user-content {
  margin: 0 50px;
  background: $whiteColor;
  height: calc(100vh - 200px);
  border-radius: 0 0 8px 8px;
}

// TODO: get rid of this/create a better solution to fix icon positions
.empty-div {
  padding: 0px 10px;
}

.sampling-ops-page {
  p {
    margin: 0px;
  }

  .fields-table-container {
    display: flex;
    flex: 1;
    background-color: $black10;
    flex-direction: column;
    .body-content {
      padding: 20px 40px;
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  .fields-search-container {
    width: 100%;
    height: 44px;
    background-color: $searchBlue;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .sampling-ops-map-container {
    height: 100%;
    max-width: 100%;
  }

  .fields-footer {
    width: 100%;
    height: 80px;
    top: 755px;
    left: 56px;
    background-color: $black25;
    display: flex;
    align-items: center;
    padding: 0 30px;
    align-self: end;

    .footer-container {
      display: flex;
      flex: 1;
      height: 100%;
      padding: 20px 15px;
    }

    .footer-subcontainer {
      background-color: $lightGrey;
      height: 100%;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      color: $whiteColor;
    }
  }
}

.MuiDataGrid-panel {
  .MuiDataGrid-panelFooter {
    .MuiButton-root {
      color: $black25;
    }
  }
}
